import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/SEO';

import tw from 'twin.macro';
import styled from 'styled-components';
// import { graphql, useStaticQuery } from 'gatsby';

import { css } from 'styled-components/macro'; //eslint-disable-line

import { SectionHeading } from '../components/misc/Typography';
import { Container, ContentWithPaddingXl } from '../components/misc/Layouts';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ol {
    ${tw`list-decimal list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const PrivacyPolicy = ({ headingText = 'Privacy Policy' }) => {
  // const { page } = useStaticQuery(contactQuery);
  return (
    <Layout>
      <Seo
        title="Privacy Policy"
        description="The CountMyCents Tax Services privacy policy. How we collect and use your data."
      />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>This policy was last modified on January 10,2022.</p>
            <p>
              This Privacy Policy describes the policies and procedures of CountMyCents Tax
              Services, (“CountMyCents Tax Services”, “we”, “our” or “us”) on the collection, use
              and disclosure of your information on countmycents.com (the “Site”) and the services,
              features, content or applications we offer (collectively with the Site, the
              “Services”). We receive information about you from various sources, including: (i) if
              you register for the Site and the Services, through your user account on the Services
              (your “Account”); (ii) your use of the Services generally; and (iii) from third party
              websites and services. When you use the Services, you are consenting to the
              collection, transfer, manipulation, storage, disclosure and other uses of your
              information as described in this Privacy Policy.
            </p>
            <h1>What Does This Privacy Policy Cover?</h1>
            <p>
              This Privacy Policy covers the treatment of personally identifiable information
              (“Personal Information”) gathered when you are using or accessing the Services. This
              Privacy Policy also covers our treatment of any Personal Information that our business
              partners share with us or that we share with our business partners.
            </p>
            <p>
              This Privacy Policy does not apply to the practices of third parties that we do not
              own or control, including but not limited to any third party websites, services and
              applications (“Third Party Services”) that you elect to access through the Service or
              to individuals that we do not manage or employ. While we attempt to facilitate access
              only to those Third Party Services that share our respect for your privacy, we cannot
              take responsibility for the content or privacy policies of those Third Party Services.
              We encourage you to carefully review the privacy policies of any Third Party Services
              you access.
            </p>
            <h1>What Information Do We Collect?</h1>
            <p>
              The information we gather enables us to personalize, improve and continue to operate
              the Services. In connection with certain aspects of the Services, we may request,
              collect and/or display some of your Personal Information. We collect the following
              types of information from our users.
            </p>
            <h1>Account Information:</h1>
            <p>
              When you create an Account, you will provide information that could be Personal
              Information, such as your username, password, name and email address. You acknowledge
              that this information may be personal to you, and by creating an Account on the
              Services and providing Personal Information to us, you allow others, including us, to
              identify you and therefore may not be anonymous. We may use your contact information
              to send you information about our Services, but only rarely when we feel such
              information is important. You may unsubscribe from these messages through your Account
              settings, although we, regardless, reserve the right to contact you when we believe it
              is necessary, such as for account recovery purposes.
            </p>
            <h1>Financial Information:</h1>
            <p>
              We do not currently collect financial information, such as your payment method (valid
              credit card number, type, expiration date or other financial information); that
              information is collected and stored by our third party payment processing company (the
              “Payment Processor”), and use and storage of that information is governed by the
              Payment Processor’s applicable terms of service and privacy policy.
            </p>
            <h1>IP Address Information and Other Information Collected Automatically:</h1>
            <p>
              We automatically receive and record information from your web browser when you
              interact with the Services, including your IP address and cookie information. This
              information is used for fighting spam/malware and also to facilitate collection of
              data concerning your interaction with the Services (e.g., what links you have clicked
              on).
            </p>

            <p>
              Generally, the Services automatically collect usage information, such as the number
              and frequency of visitors to the Site. We may use this data in aggregate form, that
              is, as a statistical measure, but not in a manner that would identify you personally.
              This type of aggregate data enables us and third parties authorized by us to figure
              out how often individuals use parts of the Services so that we can analyze and improve
              them.
            </p>

            <h1>Email Communications:</h1>
            <p>
              We may receive a confirmation when you open an email from us. We use this confirmation
              to improve our customer service.
            </p>

            <h1>Information Collected Using Cookies:</h1>
            <p>
              Cookies are pieces of text that may be provided to your computer through your web
              browser when you access a website. Your browser stores cookies in a manner associated
              with each website you visit. We use cookies to enable our servers to recognize your
              web browser and tell us how and when you visit the Site and otherwise use the Services
              through the Internet.
            </p>

            <p>
              Our cookies do not, by themselves, contain Personal Information, and we do not combine
              the general information collected through cookies with other Personal Information to
              tell us who you are. As noted, however, we do use cookies to identify that your web
              browser has accessed aspects of the Services and may associate that information with
              your Account if you have one.
            </p>

            <p>
              Most browsers have an option for turning off the cookie feature, which will prevent
              your browser from accepting new cookies, as well as (depending on the sophistication
              of your browser software) allowing you to decide on acceptance of each new cookie in a
              variety of ways. We strongly recommend that you leave cookies active, because they
              enable you to take advantage the most attractive features of the Services.
            </p>
            <p>
              This Privacy Policy covers our use of cookies only and does not cover the use of
              cookies by third parties. We do not control when or how third parties place cookies on
              your computer. For example, third party websites to which a link points may set
              cookies on your computer.
            </p>

            <h1>Aggregate Information:</h1>
            <p>
              We collect statistical information about how both unregistered and registered users,
              collectively, use the Services (“Aggregate Information”). Some of this information is
              derived from Personal Information. This statistical information is not Personal
              Information and cannot be tied back to you, your Account or your web browser.
            </p>

            <h1>How, and With Whom, Is My Information Shared?</h1>
            <p>
              The Services are designed to help you share information with others. As a result, some
              of the information generated through the Services is shared publicly or with third
              parties.
            </p>

            <h1>Public Information about Your Activity on the Services:</h1>
            <p>
              Some of your activity on and through the Services is public by default. This may
              include, but is not limited to, content you have posted publicly on the Site or
              otherwise through the Services.
            </p>

            <p>
              Registered users may have some of this information associated with their Accounts.
              Unregistered users will not have this association, but information concerning their
              use of the Services (such as what pages they have visited) may be tracked anonymously
              through the use of cookies and stored by us.
            </p>

            <p>
              Please also remember that if you choose to provide Personal Information using certain
              public features of the Services, then that information is governed by the privacy
              settings of those particular features and may be publicly available. Individuals
              reading such information may use or disclose it to other individuals or entities
              without our control and without your knowledge, and search engines may index that
              information. We therefore urge you to think carefully about including any specific
              information you may deem private in content that you create or information that you
              submit through the Services.
            </p>

            <h1>IP Address Information:</h1>
            <p>
              While we collect and store IP address information, that information is not made
              public. We do at times, however, share this information with our partners, service
              providers and other persons with whom we conduct business, and as otherwise specified
              in this Privacy Policy.
            </p>

            <h1>Information You Elect to Share:</h1>
            <p>
              You may access other Third Party Services through the Services, for example by
              clicking on links to those Third Party Services from within the Site. We are not
              responsible for the privacy policies and/or practices of these Third Party Services,
              and you are responsible for reading and understanding those Third Party Services’
              privacy policies. This Privacy Policy only governs information collected on the
              Services.
            </p>

            <h1>Aggregate Information:</h1>
            <p>
              We share Aggregate Information with our partners, service providers and other persons
              with whom we conduct business. We share this type of statistical data so that our
              partners can understand how and how often people use our Services and their services
              or websites, which facilitates improving both their services and how our Services
              interface with them. In addition, these third parties may share with us non-private,
              aggregated or otherwise non Personal Information about you that they have
              independently developed or acquired.
            </p>
            <h1>Email Communications with Us:</h1>
            <p>
              As part of the Services, you may occasionally receive email and other communications
              from us, such as communications relating to your Account. Communications relating to
              your Account will only be sent for purposes important to the Services, such as
              password recovery.
            </p>
            <h1>User Profile Information:</h1>
            <p>
              User profile information including your username and other information you enter may
              be displayed to other users to facilitate user interaction within the Services. We
              will not directly reveal user email addresses to other users.
            </p>
            <h1>Financial Information:</h1>
            <p>
              As stated above, we do not currently collect financial information, as that
              information is collected and stored by our Payment Processor. However, we may from
              time to time request and receive some of your financial information from our Payment
              Processor for the purposes of completing transactions you have initiated through the
              Services, enrolling you in discount, rebate, and other programs in which you elect to
              participate, protecting against or identify possible fraudulent transactions, and
              otherwise as needed to manage our business.
            </p>
            <h1>Information Shared with Our Agents:</h1>
            <p>
              We employ and contract with people and other entities that perform certain tasks on
              our behalf and who are under our control (our “Agents”). We may need to share Personal
              Information with our Agents in order to provide products or services to you. Unless we
              tell you differently, our Agents do not have any right to use Personal Information or
              other information we share with them beyond what is necessary to assist us. You hereby
              consent to our sharing of Personal Information with our Agents.
            </p>
            <h1>Information Shared with Our Agents:</h1>
            <p>
              We employ and contract with people and other entities that perform certain tasks on
              our behalf and who are under our control (our “Agents”). We may need to share Personal
              Information with our Agents in order to provide products or services to you. Unless we
              tell you differently, our Agents do not have any right to use Personal Information or
              other information we share with them beyond what is necessary to assist us. You hereby
              consent to our sharing of Personal Information with our Agents.
            </p>
            <h1>Information Disclosed Pursuant to Business Transfers:</h1>
            <p>
              We employ and contract with people and other entities that perform certain tasks on
              our behalf and who are under our control (our “Agents”). We may need to share Personal
              Information with our Agents in order to provide products or services to you. Unless we
              tell you differently, our Agents do not have any right to use Personal Information or
              other information we share with them beyond what is necessary to assist us. You hereby
              consent to our sharing of Personal Information with our Agents.
            </p>
            <h1>Information Disclosed Pursuant to Business Transfers:</h1>
            <p>
              In some cases, we may choose to buy or sell assets. In these types of transactions,
              user information is typically one of the transferred business assets. Moreover, if we,
              or substantially all of our assets, were acquired, or if we go out of business or
              enter bankruptcy, user information would be one of the assets that is transferred or
              acquired by a third party. You acknowledge that such transfers may occur, and that any
              acquirer of us or our assets may continue to use your Personal Information as set
              forth in this policy.
            </p>
            <h1>Information Disclosed for Our Protection and the Protection of Others:</h1>
            <p>
              We also reserve the right to access, read, preserve, and disclose any information as
              it reasonably believes is necessary to
            </p>
            <ol>
              <li>
                <p>
                  satisfy any applicable law, regulation, legal process or governmental request,
                </p>
              </li>
              <li>
                <p>
                  enforce this Privacy Policy and our Terms of Service, including investigation of
                  potential violations hereof,
                </p>
              </li>
              <li>
                <p>detect, prevent, or otherwise address fraud, security or technical issues,</p>
              </li>
              <li>
                <p>
                  satisfy any applicable law, regulation, legal process or governmental request,
                </p>
              </li>
              <li>
                <p>respond to user support requests, or</p>
              </li>
              <li>
                <p>
                  protect our rights, property or safety, our users and the public. This includes
                  exchanging information with other companies and organizations for fraud protection
                  and spam/malware prevention.
                </p>
              </li>
            </ol>

            <h1>Information We Share With Your Consent:</h1>
            <p>
              Except as set forth above, you will be notified when your Personal Information may be
              shared with third parties, and will be able to prevent the sharing of this
              information.
            </p>
            <h1>Is Information About Me Secure?</h1>
            <p>
              Your Account information will be protected by a password for your privacy and
              security. You need to prevent unauthorized access to your Account and Personal
              Information by selecting and protecting your password appropriately and limiting
              access to your computer and browser by signing off after you have finished accessing
              your Account.
            </p>
            <p>
              Your Account information will be protected by a password for your privacy and
              security. You need to prevent unauthorized access to your Account and Personal
              Information by selecting and protecting your password appropriately and limiting
              access to your computer and browser by signing off after you have finished accessing
              your Account.
            </p>
            <p>
              We seek to protect Account information to ensure that it is kept private; however, we
              cannot guarantee the security of any Account information. Unauthorized entry or use,
              hardware or software failure, and other factors, may compromise the security of user
              information at any time.
            </p>
            <p>
              We otherwise store all of your information, including your IP address information,
              using industry-standard techniques. We do not guarantee or warrant that such
              techniques will prevent unauthorized access to information about you that we store,
              Personal Information or otherwise.
            </p>
            <h1>What Information of Mine Can I Access?</h1>
            <p>
              If you are a registered user, you can access information associated with your Account
              by logging into the Services. Registered and unregistered users can access and delete
              cookies through their web browser settings.
            </p>
            <h1>How Can I Delete My Account?</h1>
            <p>
              Should you ever decide to delete your Account, you may do so by emailing
              info@countmycents.com
            </p>
            <p>
              If you terminate your Account, any association between your Account and information we
              store will no longer be accessible through your Account. However, given the nature of
              sharing on the Services, any public activity on your Account prior to deletion will
              remain stored on our servers and will remain accessible to the public.
            </p>
            <h1>What Choices Do I Have Regarding My Information?</h1>
            <p>
              You can always opt not to disclose certain information to us, even though it may be
              needed to take advantage of some of our features.
            </p>
            <p>
              You can delete your Account. Please note that we will need to verify that you have the
              authority to delete the Account, and activity generated prior to deletion will remain
              stored by us and may be publicly accessible.
            </p>
            <h1>What Happens When There Are Changes to this Privacy Policy?</h1>
            <p>
              We may amend this Privacy Policy from time to time. Use of information we collect now
              is subject to the Privacy Policy in effect at the time such information is used. If we
              make changes in the way we collect or use information, we will notify you by posting
              an announcement on the Services or sending you an email. A user is bound by any
              changes to the Privacy Policy when he or she uses the Services after such changes have
              been first posted.
            </p>
            <h1>What If I Have Questions or Concerns?</h1>
            <p>
              If you have any questions or concerns regarding privacy using the Services, please
              send us a detailed message to info@countmycents.com
            </p>
            <p>We will make every effort to resolve your concerns.</p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
